<template>
  <div class="calendar">
    <loader style="z-index: 1050; height: 100vh !important;" v-if="loading && !hasBeenLoaded"></loader>
    <div class="container h-100 d-flex align-items-center">
      <div class="row w-md-100 calendar__content flex-column flex-md-row">
        <div class="calendar-header col-12 col-md-6 h-100 d-flex flex-column justify-content-center">
          <div class="title">
            <h4>
              Kies jullie datum
            </h4>
          </div>

          <p>
            Jullie hebben enkele dagen om na te denken over jullie ideale datum. De deadline hiervoor staat aangegeven in de naar jullie verstuurde brief.
          </p>

        </div>

        <div 
          class="
            card
            card-calendar
            col-12 col-md-6
            px-sm-3
            py-sm-5
            px-1
            py-3
            d-flex
            flex-column
            justify-content-between
            position-relative
            overflow-hidden
            mh-100
          "
        >
          <loader style="border-radius: 32px;" v-if="loading"></loader>
          <div class="datePicker__content h-100">
            <select name="month" v-model="selectedMonth">
              <option
                v-for="(month, index) in months"
                :key="`month-${index}`"
                :value="month.monthString"
              >
                {{ month.humanReadable }}
              </option>
            </select>

            <ul 
              class="datePicker__dateList h-100"
              ref="dateList"
            >
              <template v-if="dates && getClubData">
                <li
                  v-for="(date, index) in dates"
                  :key="`data-${index}`"
                  :class="[
                    date.club ? 'disabled' : '',
                    eventId == date.id ? 'selected' : '',
                  ]"
                  @click.self="selectDate(date.id)"
                >
                  <div class="dateList__date text-center">
                    <h3>
                      <span v-if="date.humanReadable">
                        {{ date.humanReadable }}
                      </span>
                    </h3>
                  </div>

                  <div class="dateList__discription">
                    <p
                      v-if="!date.club"
                      class="m-0"
                      v-html="date.description"
                    ></p>

                    <p v-else>Al gereserveerd door {{ date.club.name }}</p>
                  </div>
                </li>
              </template>

              <h4
                v-else-if="getClubData"
                class="w-100 text-center position-absolute"
                style="top: 50%; left: 50%; transform: translate(-50%, -50%)"
              ></h4>

              <h4
                v-else
                class="w-100 text-center position-absolute"
                style="top: 50%; left: 50%; transform: translate(-50%, -50%)"
              >
                Voor deze maand zijn er geen datums beschikbaar
              </h4>
            </ul>
          </div>

        </div>

        <nextButton
          v-on:click.native="setUpReservation"
          :nextStep="nextStep"
          :className="!eventId ? 'disabled' : ''"
          class="mt-auto w-100"
        ></nextButton>

        <errorModal
          v-if="error"
          :whatsappMSG="whatsappMSG"
          :errorTitle="errorTitle"
          :errorMsg="errorMsg"
          style="position: sticky; bottom: 0;"
        ></errorModal>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

import nextButton from '@/components/next_button.vue';
import loader from '@/components/loader.vue';
import errorModal from '@/components/errorModal.vue';

import { mapGetters, mapActions } from 'vuex';

import { executeApiCall } from '@/apiClient';

export default {
  name: 'calendar',
  components: {
    nextButton,
    loader,
    errorModal,
  },
  data() {
    return {
      selectedMonth: null,
      dates: [],
      months: [],
      nextStep: '/contacts',
      ready: false,
      eventId: false,
      loading: false,
      errorTitle: 'Geen gegevens gevonden',
      errorMsg: 'Probeer het nog een keer of stuur een bericht naar onze klanten service.',
      whatsappMSG: 'Hoi! Ik kan de calendar van clubactie niet laden. Kunnen jullie me hierbij helpen?',
      error: false,
      hasBeenLoaded: false,
    };
  },

  beforeMount() {
    this.getMonths();
    this.getDates();
  },

  methods: {
    ...mapActions(['setCurrentReservation']),

    selectDate(eventId) {
      this.$refs.dateList.classList += " selectDate";
      this.eventId = eventId;
    },

    setUpReservation() {
      var resevationObj = {
        clubId: this.getClubData.id,
        clubName: this.getClubData.name,
        eventId: this.eventId,
      };

      this.setCurrentReservation(resevationObj);
    },

    async getMonths() {
      const res = await executeApiCall('post', '/months', { getAll: false });

      if (!res?.ok) {
        alert(res?.data?.message);
        return;
      }

      const dates = res.data?.dates || [];

      const monthStrings = [];

      this.months = [];

      for (const date of dates) {
        const monthString = format(
          new Date(date.eventDate),
          "yyyy-MM-'01T00:00:00'.SSSxxx"
        );

        if (!monthStrings.includes(monthString)) {
          monthStrings.push(monthString);

          const humanReadable = format(new Date(date.eventDate), 'MMM yyyy', {
            locale: nl,
          });

          this.months.push({
            monthString,
            humanReadable,
          });
        }
      }

      this.selectedMonth = monthStrings[0];
    },

    async getDates() {
      this.loading = true;

      this.dates = [];

      const res = await executeApiCall('post', '/dates', {
        month: this.selectedMonth,
      });

      this.loading = false;
      this.hasBeenLoaded = true;

      if (!res?.ok) {
        alert(res?.data?.message);
        return;
      }

      let dates = res.data?.dates || [];

      dates = dates.map((date) => {
        const humanReadable = format(new Date(date.eventDate), 'eeeeee dd', {
          locale: nl,
        });
        return {
          ...date,
          humanReadable,
        };
      });

      this.dates = dates;
    },

  },

  computed: {
    ...mapGetters(['getClubData']),
  },

  watch: {
    selectedMonth() {
      this.getDates();
    },
  },
};
</script>
